import { useCallback } from 'react';
import { type AxiosRequestConfig } from 'axios';
import {
  CreatePartnerOrganizationRequest,
  NoContentResult,
  GetOrganizationsResponse,
  GetOrganizationByIdResponse,
  GetVerifiedDomainsResponse,
  GetOrganizationHistoricLicenseDataResponse,
  GetOrganizationAdminsResponse,
  UpdatePartnerOrganizationRequest,
  GetOrganizationPortalAccessInfoResponse,
  GetOrganizationAuditLogsResponse,
  ExportOrganizationLicenseDataRequest,
  OkResult,
  GetOrganizationsForLookupResponse,
  ExportOrganizationLicenseDataForPartnerRequest,
  GetExtendedOrganizationsResponse,
} from '@uniqkey-backend-partner/api-client';
import { TimeEnum } from '@uniqkey-frontend/shared-app';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor, auditLogPayloadExtractor } from '../../helpers/apiClients';
import {
  TGetOrganizationLicensesHistoryMethod,
  TGetOrganizationsMethod,
  TGetOrganizationAdminsMethod,
  TGetOrganizationVerifiedDomainsMethod,
  TGetOrganizationAuditLogsMethod,
  TGetOrganizationsForLookupMethod,
  TGetExtendedOrganizationsMethod,
} from './interfaces';

const usePartnerOrganizationsAPI = () => {
  const { partnerOrganizationsAPIClient } = useApiClients();

  const createOrganization = useCallback(
    (params: CreatePartnerOrganizationRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerOrganizationsAPIClient],
  );

  const getOrganizations = useCallback<TGetOrganizationsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        countryFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGet(
        page,
        pageLength,
        partnerId,
        countryFilter,
        searchQuery,
        undefined, // TODO NEXTGEN-4703 movedFromPartnerId
        undefined, // TODO NEXTGEN-4703 allMovedPartners
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetOrganizationsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationById = useCallback(
    (organizationId: string, options?: AxiosRequestConfig)
      : Promise<GetOrganizationByIdResponse> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsOrganizationIdGet(organizationId, options)
      .then(dataExtractor<GetOrganizationByIdResponse>()),
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationVerifiedDomains = useCallback<TGetOrganizationVerifiedDomainsMethod>(
    (params, axiosRequestConfig) => {
      const {
        organizationId,
        page,
        pageLength,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetVerifiedDomainsGet(
        organizationId,
        page,
        pageLength,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetVerifiedDomainsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationLicensesHistory = useCallback<TGetOrganizationLicensesHistoryMethod>(
    (params, axiosRequestConfig) => {
      const {
        organizationId,
        page,
        pageLength,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetHistoricLicenseDataGet(
        organizationId,
        page,
        pageLength,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetOrganizationHistoricLicenseDataResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationAdmins = useCallback<TGetOrganizationAdminsMethod>(
    (params, axiosRequestConfig) => {
      const {
        organizationId,
        page,
        pageLength,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetAdminsGet(
        organizationId,
        page,
        pageLength,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetOrganizationAdminsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const requestOrganizationLicenseDataExport = useCallback(
    (
      exportOrganizationLicenseDataRequest: ExportOrganizationLicenseDataRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsExportLicenseDataPost(exportOrganizationLicenseDataRequest, {
        timeout: TimeEnum.OneMinute,
        ...options,
      })
      .then(dataExtractor<OkResult>()),
    [partnerOrganizationsAPIClient],
  );

  const updateOrganization = useCallback(
    (
      updatePartnerOrganizationRequest?: UpdatePartnerOrganizationRequest,
      options?: AxiosRequestConfig,
    ): Promise<NoContentResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsPut(updatePartnerOrganizationRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerOrganizationsAPIClient],
  );

  const getExtendedOrganizations = useCallback<TGetExtendedOrganizationsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        countryFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetExtendedGet(
        page,
        pageLength,
        partnerId,
        countryFilter,
        searchQuery,
        undefined, // TODO NEXTGEN-4703 movedFromPartnerId
        undefined, // TODO NEXTGEN-4703 allMovedPartners
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetExtendedOrganizationsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationAccessInfo = useCallback(
    (
      organizationId: string,
      options?: AxiosRequestConfig,
    ): Promise<GetOrganizationPortalAccessInfoResponse> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsGetAccessInfoGet(organizationId, options)
      .then(dataExtractor<GetOrganizationPortalAccessInfoResponse>()),
    [partnerOrganizationsAPIClient],
  );

  const requestOrganizationLogin = useCallback(
    (organizationId: string, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsRequestOrganizationLoginPost(organizationId, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationAuditLogs = useCallback<TGetOrganizationAuditLogsMethod>(
    (params, axiosRequestConfig) => {
      const {
        organizationId,
        page,
        pageLength,
        employeeAccountId = undefined,
        showOnlySupporters = undefined,
        beforeDate = undefined,
        afterDate = undefined,
      } = params;
      return partnerOrganizationsAPIClient
        .apiV1PartnerOrganizationsGetAuditLogsGet(
          organizationId,
          page,
          pageLength,
          employeeAccountId,
          showOnlySupporters,
          beforeDate,
          afterDate,
          axiosRequestConfig,
        )
        .then(dataExtractor<GetOrganizationAuditLogsResponse>())
        .then((response) => ({
          ...response,
          data: response.data.map(((el) => auditLogPayloadExtractor(el))),
        }));
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationsForLookup = useCallback<TGetOrganizationsForLookupMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        searchQuery = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetForLookupGet(
        page,
        pageLength,
        partnerId,
        searchQuery,
        axiosRequestConfig,
      ).then(dataExtractor<GetOrganizationsForLookupResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getExportDataResult = useCallback(
    (fileName?: string, options?: AxiosRequestConfig)
      : Promise<string> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsExportDataResultGet(fileName, options)
      // BE type is object, but the actual data is string.
      .then(dataExtractor<any>()),
    [partnerOrganizationsAPIClient],
  );

  const requestOrganizationForPartnerLicenseDataExport = useCallback(
    (
      exportOrganizationLicenseDataForPartnerRequest?:
        ExportOrganizationLicenseDataForPartnerRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsExportLicenseForPartnerDataPost(
        exportOrganizationLicenseDataForPartnerRequest,
        options,
      )
      .then(dataExtractor<OkResult>()),
    [partnerOrganizationsAPIClient],
  );

  return ({
    createOrganization,
    getOrganizations,
    getOrganizationById,
    getOrganizationVerifiedDomains,
    getOrganizationLicensesHistory,
    getOrganizationAdmins,
    requestOrganizationLicenseDataExport,
    updateOrganization,
    getExtendedOrganizations,
    getOrganizationAccessInfo,
    requestOrganizationLogin,
    getOrganizationAuditLogs,
    getOrganizationsForLookup,
    getExportDataResult,
    requestOrganizationForPartnerLicenseDataExport,
  });
};

export default usePartnerOrganizationsAPI;
